import {createSlice} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import {createSelector} from 'reselect';

import {colleaguePlaceholderPhoto} from '../constants/images';
import {ROUTES} from '../constants/routes';

import {
	getMember,
	getOrganization,
	getOffices,
	getOrganizationLogo,
	memberChangeLastSelectedOffice,
	getMemberPhoto,
	getBadgeVerificationCode,
	updateMemberLanguage,
	validateAuthCode,
} from '../api/index';
import {getDataFromAPIForOffice} from '../functions/getDataFromAPIForOffice';
import i18next from 'i18next';
import {checkEmergency} from './cards';
import {getFeatureTranslations} from '../api/additionals';
import {updateNotificationsList} from './profile';
import {loadOfficeElements} from './bookingProcess/mapView';
import {getStatisticsForOffice} from '../functions/getStatisticsForOffice';
import {editFavoriteOffice, getMaplesAuthKey} from '../api/member';
import {editFavoriteAspenItem} from '../api/aspen';
import i18n from '../i18n';

//slice from store that holds authentication data. AuthData includes static data as well such as : officeData, organizationData or areasData.
const slice = createSlice({
	name: 'auth',
	initialState: {
		id: null,
		data: {
			userData: null,
			organizationData: null,
			actionSheetData: null,
			organizationLogo: null,
			locale: 'de',
			enable12HourFormat: false,
			dateFormat: window.localStorage.getItem('dateFormat') ?? 'dd/LL/yyyy',
			calendarFormatMonFirst: window.localStorage.getItem('calendarFormatSunToSat') !== 'true', // calendar should show Monday as first week or not
			offices: [],
			areas: [],
			lastFetch: null,
			errorMessage: null,
			teakTypeArray: [],
			teakTypeLists: {},
			selectedOffice: {},
			statisticsForSelectedOffice: {},
			teakFeatures: [],
			featureTranslations: [],
			badge: {
				qrCodeUrl: null,
				lastFetchOfCode: null,
			},
			groupedOffices: null,
			memberPhotos: [],
			officeBanners: [],
			areaBanners: [],
			toastMenuData: null,
			lastFetchOfStatistics: {},
		},
		ui: {
			theme: null,
			loadNewOffice: null,
			showLoadingScreen: true,
			majorErrorMessage: null,
			showBottomMenu: true,
			runTutorial: false,
			showLoadingSpinnerQrCodeCarousel: true,
			authCodeVerified: false,
			toastOpen: false,
			switchingOfficeLoader: false,
			loadingStatistics: false,
			disableDashboard: false,
			actionSheetOpen: false,
		},
	},
	reducers: {
		authDataAdded: (auth, action) => {
			const {user, organization, offices, areas, areasTreeList, teakTypeArray, organizationLogo, lastSelectedOffice, aspenTypes} =
				action.payload;
			auth.id = user?._id;
			auth.id = user?._id;
			auth.data.userData = user;
			auth.data.organizationData = organization;
			auth.data.offices = offices;
			auth.data.areas = areas;
			auth.data.areasTreeList = areasTreeList;
			auth.data.lastFetch = DateTime.now().toUTC().toISO();
			auth.data.teakTypeArray = teakTypeArray;
			auth.data.selectedOffice = lastSelectedOffice;
			if (lastSelectedOffice) {
				auth.data.selectedOffice.id = lastSelectedOffice?._id;
			}
			auth.data.aspenTypes = aspenTypes;
			auth.data.teakFeatures = action.payload.teakFeatures;
			auth.data.organizationLogo = organizationLogo;
		},
		newOfficeDataAdded: (auth, action) => {
			auth.data.offices = action.payload.offices;
			auth.data.areas = action.payload.areas;
			auth.data.areasTreeList = action.payload.areasTreeList;
			auth.data.teakTypeArray = action.payload.teakTypeArray;
			auth.data.teakFeatures = action.payload.teakFeatures;
			auth.data.aspenTypes = action.payload.aspenTypes;
		},
		localeChanged: (auth, action) => {
			auth.data.locale = action.payload;
		},
		enable12HourFormatReceived: (auth, action) => {
			auth.data.enable12HourFormat = action.payload;
		},
		themeAdded: (auth, action) => {
			auth.ui.theme = action.payload;
		},
		errorReceived: (auth, action) => {
			auth.ui.errorMessage = action.payload;
		},
		areasArrayUpdated: (auth, action) => {
			auth.data.areas = action.payload;
		},
		majorErrorReceived: (auth, action) => {
			auth.ui.majorErrorMessage = action.payload;
		},
		loadNewOfficeReceived: (auth, action) => {
			auth.ui.loadNewOffice = action.payload;
		},
		showLoadingScreenReceived: (auth, action) => {
			auth.ui.showLoadingScreen = action.payload;
		},
		newSelectedOfficeReceived: (auth, action) => {
			auth.data.selectedOffice = action.payload;
		},
		badgeInfoReceived: (auth, action) => {
			auth.data.badge.qrCodeUrl = action.payload.url;
			auth.data.badge.lastFetchOfCode = action.payload.lastFetch;
		},
		newUserDataReceived: (auth, action) => {
			auth.data.userData = action.payload;
		},
		showBottomMenuReceived: (auth, action) => {
			auth.ui.showBottomMenu = action.payload;
		},
		runTutorialReceived: (auth, action) => {
			auth.ui.runTutorial = action.payload;
		},
		showLoadingSpinnerQrCodeCarouselReceived: (auth, action) => {
			auth.ui.showLoadingSpinnerQrCodeCarousel = action.payload;
		},
		organizationLogoChanged: (auth, action) => {
			auth.data.organizationLogo = action.payload;
		},
		groupedOfficesReceived: (auth, action) => {
			auth.data.groupedOffices = action.payload;
		},
		favoriteTeakEntitiesUpdated: (auth, action) => {
			auth.data.userData.biro.favoriteTeakEntities = action.payload;
		},
		favoriteAspenItemsUpdated: (auth, action) => {
			auth.data.userData.biro.favoriteAspenItems = action.payload;
		},
		newOfficeForFilterReceived: (auth, action) => {
			auth.data.userData.biro.filterFavoritesByOffice = action.payload;
		},
		codeWasValidated: (auth, action) => {
			auth.ui.authCodeVerified = action.payload;
		},
		colleagueListUpdated: (auth, action) => {
			auth.data.userData.colleagueList = action.payload;
		},
		newMemberPhotosReceived: (auth, action) => {
			auth.data.memberPhotos = action.payload;
		},
		newOfficeBannersReceived: (auth, action) => {
			auth.data.officeBanners = action.payload;
		},
		newAreaBannersReceived: (auth, action) => {
			auth.data.areaBanners = action.payload;
		},
		calendarFormatChanged: (auth, action) => {
			auth.data.calendarFormatMonFirst = action.payload;
		},
		switchingOfficeLoaderUpdated: (auth, action) => {
			auth.ui.switchingOfficeLoader = action.payload;
		},
		toastBookingChanged: (auth, action) => {
			auth.ui.toastOpen = action.payload;
		},
		statisticsForSelectedOfficeReceived: (auth, action) => {
			auth.data.statisticsForSelectedOffice = action.payload;
		},
		loadingStatisticsReceived: (auth, action) => {
			auth.ui.loadingStatistics = action.payload;
		},
		updateDisabledDashboardState: (auth, action) => {
			auth.ui.disableDashboard = action.payload;
		},
		featureTranslationsReceived: (auth, action) => {
			auth.data.featureTranslations = action.payload;
		},
		actionSheetChanged: (auth, action) => {
			auth.ui.actionSheetOpen = action.payload;
		},
		actionSheetDataChanged: (auth, action) => {
			auth.data.actionSheetData = action.payload;
		},
		lastFetchOfStatisticsChanged: (auth, action) => {
			auth.data.lastFetchOfStatistics = action.payload;
		},
		favoriteOfficesUpdated: (auth, action) => {
			auth.data.userData.biro.favoriteOffices = action.payload;
		},
		dateFormatUpdated: (auth, action) => {
			auth.data.dateFormat = action.payload;
		},
	},
});

export const {
	authDataAdded,
	newOfficeDataAdded,
	localeChanged,
	enable12HourFormatReceived,
	errorReceived,
	areasArrayUpdated,
	majorErrorReceived,
	newSelectedOfficeReceived,
	badgeInfoReceived,
	newUserDataReceived,
	showBottomMenuReceived,
	themeAdded,
	showLoadingScreenReceived,
	loadNewOfficeReceived,
	runTutorialReceived,
	showLoadingSpinnerQrCodeCarouselReceived,
	organizationLogoChanged,
	groupedOfficesReceived,
	favoriteTeakEntitiesUpdated,
	favoriteAspenItemsUpdated,
	newOfficeForFilterReceived,
	codeWasValidated,
	colleagueListUpdated,
	newMemberPhotosReceived,
	newOfficeBannersReceived,
	newAreaBannersReceived,
	calendarFormatChanged,
	switchingOfficeLoaderUpdated,
	toastBookingChanged,
	statisticsForSelectedOfficeReceived,
	loadingStatisticsReceived,
	updateDisabledDashboardState,
	featureTranslationsReceived,
	actionSheetChanged,
	actionSheetDataChanged,
	lastFetchOfStatisticsChanged,
	favoriteOfficesUpdated,
	dateFormatUpdated,
} = slice.actions;

export default slice.reducer;

export const addUserData = (userData) => async (dispatch, getState) => {
	dispatch({type: newUserDataReceived.type, payload: userData});
};

export const addAuthData = (userData, history) => async (dispatch, getState) => {
	const authData = {};
	const userId = userData._id;
	if (navigator.language === 'en-us' || navigator.language === 'en-US' || window.localStorage.getItem('timeFormat12hrs') === 'true') {
		dispatch({type: localeChanged.type, payload: 'en-US'});
		dispatch({type: enable12HourFormatReceived.type, payload: true});
	}
	const lastFetch = getState().auth.data.lastFetch;

	if (lastFetch && DateTime.now().toUTC() < DateTime.fromISO(lastFetch).toUTC().plus({minutes: 10})) return;

	try {
		authData.user = {...userData};

		if (window.ReactNativeWebView) {
			const authKey = await getMaplesAuthKey(userId);
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					email: userData.email,
					authKey: authKey.data,
				}),
			);
		}
		dispatch(updateNotificationsList(userId));

		if (userData.language) {
			if (i18next.language !== userData.language) {
				i18next.changeLanguage(userData.language);
			}
		} else {
			updateMemberLanguage(userData._id, i18next.language.slice(0, 2));
			i18next.changeLanguage(i18next.language);
		}

		try {
			const memberPhotoResponse = await getMemberPhoto(userId, true);
			if (memberPhotoResponse.status === 204) throw 'no member photo';
			dispatch(addMemberPhoto(userData._id, window.URL.createObjectURL(new Blob([memberPhotoResponse.data]))));
			authData.user.photo = window.URL.createObjectURL(new Blob([memberPhotoResponse.data]));
		} catch (error) {
			dispatch(addMemberPhoto(userData._id, colleaguePlaceholderPhoto));
			authData.user.photo = colleaguePlaceholderPhoto;
		}
	} catch (error) {
		console.error(error);
		if (error?.response?.status === 401) {
			dispatch({
				type: showLoadingScreenReceived.type,
				payload: false,
			});
			history.push(ROUTES.LOGIN);
			return;
		} else {
			dispatch({
				type: majorErrorReceived.type,
				payload: 'errorMessages.apiCalls.member',
			});
			return;
		}
	}

	try {
		const orgResponse = await getOrganization(authData.user.organization);
		authData.organization = orgResponse.data;
		if (orgResponse?.data?.biro?.colorTheme) {
			window.localStorage.setItem('colorTheme', orgResponse.data.biro.colorTheme);
			dispatch({
				type: themeAdded.type,
				payload: orgResponse.data.biro.colorTheme,
			});
		}

		try {
			const orgLogoResponse = await getOrganizationLogo(authData.user.organization._id);
			authData.organizationLogo = window.URL.createObjectURL(new Blob([orgLogoResponse.data]));
		} catch (error) {
			authData.organizationLogo = false;
			dispatch({
				type: errorReceived.type,
				payload: 'errorMessages.apiCalls.organizationLogo',
			});
		}
	} catch (error) {
		dispatch({
			type: majorErrorReceived.type,
			payload: 'errorMessages.apiCalls.organization',
		});
		return;
	}
	let offices = [];
	let indexOfLastSelectedOffice = 0;
	try {
		const officesResponse = await getOffices();
		authData.offices = officesResponse.data;
		let findIndexOfLastSelectedOffice = authData.offices.findIndex((office) => office._id === authData.user?.biro?.lastOfficeSelection);
		indexOfLastSelectedOffice = findIndexOfLastSelectedOffice < 0 ? 0 : findIndexOfLastSelectedOffice;
		authData.offices[indexOfLastSelectedOffice].hasAllData = true;
		offices.push(...authData.offices);
	} catch (error) {
		console.log(error);
		dispatch({
			type: errorReceived.type,
			payload: 'errorMessages.apiCalls.office',
		});
	}
	const lastSelectedOfficeObject = offices[indexOfLastSelectedOffice];

	const officeDataFull = await getDataFromAPIForOffice(dispatch, [], [], [], [], [], [], lastSelectedOfficeObject);

	authData.lastSelectedOffice = lastSelectedOfficeObject;
	authData.areas = officeDataFull.areas;
	authData.areasTreeList = officeDataFull.areasTreeList;
	authData.teakTypeArray = officeDataFull.teakTypeArray;
	authData.teakFeatures = officeDataFull.teakFeatures;
	authData.aspenTypes = officeDataFull.aspenTypes;

	dispatch({type: authDataAdded.type, payload: authData});
	dispatch({type: showLoadingScreenReceived.type, payload: false});
};

export const changeOffice = (officeId, eventOfficeChanged) => (dispatch, getState) => {
	if (getState().auth.data.selectedOffice.id === officeId) {
		return;
	}
	const userData = getState().auth.data.userData;
	dispatch({type: switchingOfficeLoaderUpdated.type, payload: true});
	const offices = getState().auth.data.offices;
	const officeFromStore = offices?.find((office) => office._id === officeId);

	if (officeFromStore?.hasAllData === true) {
		dispatch({
			type: newSelectedOfficeReceived.type,
			payload: {...officeFromStore, id: officeFromStore._id},
		});
		dispatch(checkEmergency(officeId));
		dispatch({type: switchingOfficeLoaderUpdated.type, payload: false});
	} else {
		dispatch(addNewOfficeData(officeFromStore, true));
	}
	dispatch(loadOfficeElements(officeId, true));

	memberChangeLastSelectedOffice(userData._id, officeId)
		.then((response) => {
			return;
		})
		.catch((error) => {
			dispatch({
				type: errorReceived.type,
				payload: 'error on change last selected office',
			});
		});
};

export const addNewOfficeData = (office, changeToNewOffice, eventIntroCallback) => async (dispatch, getState) => {
	dispatch({type: loadNewOfficeReceived.type, payload: true});

	const areasFromStore = getState().auth.data.areas;
	const areasTreeListFromStore = getState().auth.data.areasTreeList;
	const officesFromStore = getState().auth.data.offices;
	const teakTypeArrayFromStore = getState().auth.data.teakTypeArray;
	const teakFeaturesArrayFromStore = getState().auth.data.teakFeatures;
	const aspenTypesFromStore = getState().auth.data.aspenTypes;
	const officeDataFull = await getDataFromAPIForOffice(
		dispatch,
		areasFromStore,
		areasTreeListFromStore,
		officesFromStore,
		teakTypeArrayFromStore,
		teakFeaturesArrayFromStore,
		aspenTypesFromStore,
		office,
	);
	const newSelectedOffice = officeDataFull.offices.find((el) => el._id === office._id);

	dispatch({type: newOfficeDataAdded.type, payload: officeDataFull});

	if (eventIntroCallback) {
		eventIntroCallback();
	}
	if (!changeToNewOffice) {
		dispatch({type: loadNewOfficeReceived.type, payload: false});
		return;
	}

	dispatch({
		type: newSelectedOfficeReceived.type,
		payload: {...newSelectedOffice, id: newSelectedOffice._id},
	});

	dispatch(checkEmergency(newSelectedOffice._id));

	dispatch({type: loadNewOfficeReceived.type, payload: false});
	dispatch({type: switchingOfficeLoaderUpdated.type, payload: false});
};

export const updateBadgeData = () => async (dispatch, getState) => {
	if (getState().auth.data.badge.lastFetchOfCode !== null) {
		const lastFetch = DateTime.fromISO(getState().auth.data.badge.lastFetchOfCode);

		if (lastFetch.plus({seconds: 19}) > DateTime.now()) return;
	}
	try {
		dispatch({
			type: showLoadingSpinnerQrCodeCarouselReceived.type,
			payload: true,
		});
		const badgeVerificationCodeResponse = await getBadgeVerificationCode();

		const url = process.env.REACT_APP_MAPLES + '?vCode=' + badgeVerificationCodeResponse.data.value;
		dispatch({
			type: badgeInfoReceived.type,
			payload: {url: url, lastFetch: DateTime.now().toISO()},
		});
	} catch (error) {
		dispatch({type: errorReceived.type, payload: error.message});
	}
	dispatch({
		type: showLoadingSpinnerQrCodeCarouselReceived.type,
		payload: false,
	});
};

export const updateOrganizationLogo = () => async (dispatch, getState) => {
	const org = getState().auth.data.userData.organization;
	const orgLogoResponse = await getOrganizationLogo(org._id);
	let organizationLogo = window.URL.createObjectURL(new Blob([orgLogoResponse.data]));

	dispatch({type: organizationLogoChanged.type, payload: organizationLogo});
};

export const enable12HourTimeFormat = (enable) => async (dispatch, getState) => {
	if (enable === getState().auth.data.locale);
	if (enable) {
		window.localStorage.setItem('timeFormat12hrs', 'true');
		dispatch({type: localeChanged.type, payload: 'en-US'});
		dispatch({type: enable12HourFormatReceived.type, payload: enable});
	} else {
		window.localStorage.removeItem('timeFormat12hrs');
		dispatch({type: localeChanged.type, payload: 'de'});
		dispatch({type: enable12HourFormatReceived.type, payload: enable});
	}
};

export const changeDateFormat = (dateFormat) => async (dispatch, getState) => {
	if (dateFormat === getState().auth.data.dateFormat) return;
	window.localStorage.setItem('dateFormat', dateFormat);
	dispatch({type: dateFormatUpdated.type, payload: dateFormat});
};
export const changeCalendarFormat = (mondayFirst) => async (dispatch, getState) => {
	if (mondayFirst) {
		window.localStorage.removeItem('calendarFormatSunToSat');
		dispatch({type: calendarFormatChanged.type, payload: mondayFirst});
	} else {
		window.localStorage.setItem('calendarFormatSunToSat', 'true');
		dispatch({type: calendarFormatChanged.type, payload: mondayFirst});
	}
};

export const enableBottomMenu = (show) => async (dispatch, getState) => {
	if (show === getState().auth.ui.showBottomMenu) return;
	dispatch({type: showBottomMenuReceived.type, payload: show});
};

export const reloadUserData = (callback) => async (dispatch, getState) => {
	try {
		const currentMemberData = getState().auth?.data?.userData;

		const memberResponse = await getMember(currentMemberData._id);
		if (callback) callback();
		if (memberResponse.data === currentMemberData) return;
		memberResponse.data.photo = currentMemberData.photo;
		dispatch({
			type: newUserDataReceived.type,
			payload: memberResponse.data,
		});
	} catch (error) {
		dispatch({type: errorReceived.type, payload: error.message});
	}
};

export const updateShareLocationOfUserInStore = (value) => async (dispatch, getState) => {
	const currentUserData = getState().auth?.data?.userData;
	let newUserDataObject = JSON.parse(JSON.stringify(currentUserData));
	newUserDataObject.biro.findMyColleague.enabled = value;

	dispatch({type: newUserDataReceived.type, payload: newUserDataObject});
};

export const updatePrivacyOptionOfUserInStore = (value) => async (dispatch, getState) => {
	const currentUserData = getState().auth?.data?.userData;
	let newUserDataObject = JSON.parse(JSON.stringify(currentUserData));
	newUserDataObject.biro.findMyColleague.discoverableFor = value;

	dispatch({type: newUserDataReceived.type, payload: newUserDataObject});
};

export const enableLoadingScreen = (show) => (dispatch, getState) => {
	if (getState().auth.ui.showLoadingScreen === show) return;

	dispatch({type: showLoadingScreenReceived.type, payload: show});
};

export const enableRunTutorial = (show) => (dispatch, getState) => {
	if (getState().auth.ui.runTutorial === show) return;

	dispatch({type: runTutorialReceived.type, payload: show});
};

export const updateAreaMap = (areaId, mapUrl) => (dispatch, getState) => {
	const currentAreasInStore = getState().auth.data.areas.slice();
	const index = currentAreasInStore.findIndex((area) => area._id === areaId);
	const {...copyObject} = currentAreasInStore[index];
	copyObject.mapUrl = mapUrl;
	currentAreasInStore[index] = copyObject;

	dispatch({type: areasArrayUpdated.type, payload: currentAreasInStore});
};

export const verifyAuthCode = (queryString, strategy, organization) => async (dispatch, getState) => {
	try {
		const validateResponse = await validateAuthCode(queryString, strategy, organization);
		window.localStorage.setItem('memberID', validateResponse.data.member);
		window.localStorage.setItem('loggedIn', 'true');
		dispatch({type: codeWasValidated.type, payload: true});

		const redirectUri = window.localStorage.getItem('redirectUriLogin') ?? ROUTES.DEFAULT;
		window.localStorage.removeItem('redirectUriLogin');
		window.localStorage.removeItem('loginStrategy');
		window.localStorage.removeItem('organization');
		return window.location.assign(redirectUri);
	} catch (error) {
		window.localStorage.removeItem('loginStrategy');
		window.localStorage.removeItem('organization');
		dispatch({type: errorReceived.type, payload: error.message});
		return window.location.assign(ROUTES.DEFAULT);
	}
};

export const addMemberPhoto = (id, photo) => (dispatch, getState) => {
	let memberPhotos = getState().auth.data.memberPhotos.slice();
	if (!memberPhotos.some((item) => item.id === id)) {
		memberPhotos.push({id, photo});
		dispatch({type: newMemberPhotosReceived.type, payload: memberPhotos});
	}
};

export const addOfficeBanner = (id, photo) => (dispatch, getState) => {
	let officeBanners = getState().auth.data.officeBanners.slice();
	if (!officeBanners.some((item) => item.id === id)) {
		officeBanners.push({id, photo});
		dispatch({type: newOfficeBannersReceived.type, payload: officeBanners});
	}
};

export const addAreaBanner = (id, photo) => (dispatch, getState) => {
	let areaBanners = getState().auth.data.areaBanners.slice();
	if (!areaBanners.some((item) => item.id === id)) {
		areaBanners.push({id, photo});
		dispatch({type: newAreaBannersReceived.type, payload: areaBanners});
	}
};

export const showToastBooking = (show) => (dispatch, getState) => {
	if (getState().auth.ui.toastOpen === show) return;

	dispatch({type: toastBookingChanged.type, payload: show});
};

export const loadOfficeStatistics = () => async (dispatch, getState) => {
	const officeId = getState().auth.data.selectedOffice.id;

	dispatch({type: loadingStatisticsReceived.type, payload: true});

	try {
		const newTeakTypeObject = await getStatisticsForOffice(officeId);

		dispatch({
			type: statisticsForSelectedOfficeReceived.type,
			payload: {
				office: officeId,
				statsForTeakTypes: newTeakTypeObject,
			},
		});
		dispatch({type: loadingStatisticsReceived.type, payload: false});
		dispatch({
			type: lastFetchOfStatisticsChanged.type,
			payload: DateTime.now().toISO(),
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: statisticsForSelectedOfficeReceived.type,
			payload: {error: true},
		});
		dispatch({type: loadingStatisticsReceived.type, payload: false});
	}
};

export const addFeatureTranslationsToStore = () => async (dispatch, getState) => {
	const currentFeatureTranslations = getState().auth.data.featureTranslations;
	if (currentFeatureTranslations.length > 0) return;

	try {
		const featureTranslationsResponse = await getFeatureTranslations();
		dispatch({
			type: featureTranslationsReceived.type,
			payload: featureTranslationsResponse.data,
		});
	} catch (error) {
		dispatch({type: featureTranslationsReceived.type, payload: []});
	}
};

export const updateActionSheet = (show) => (dispatch, getState) => {
	if (show === getState().auth.ui.toastOptions) return;

	dispatch({type: actionSheetChanged.type, payload: show});
};

export const updateActionSheetData = (data) => (dispatch, getState) => {
	dispatch({type: actionSheetDataChanged.type, payload: data});
};

export const processTeakEntityObjToFavorites = (teakEntityObj) => async (dispatch, getState) => {
	let favoriteTeakEntities = [...getState().auth.data?.userData?.biro?.favoriteTeakEntities];
	let favorites = favoriteTeakEntities;
	let teakEntityId;

	if (typeof teakEntityObj === 'object') {
		teakEntityId = teakEntityObj._id;
	} else {
		teakEntityId = teakEntityObj;
	}

	const teakEntityIndex = favoriteTeakEntities.findIndex((teakEntity) => teakEntity._id === teakEntityId);

	if (teakEntityIndex > -1) {
		favorites.splice(teakEntityIndex, 1);
	} else if (typeof teakEntityObj === 'object') {
		favorites = [...favoriteTeakEntities, teakEntityObj];
	}

	dispatch({type: favoriteTeakEntitiesUpdated.type, payload: favorites});
};

export const processFavoriteAspenItemObject = (aspenItem) => async (dispatch, getState) => {
	const currentFavoriteAspenItems = getState().auth.data?.userData?.biro?.favoriteAspenItems;
	const favoriteAspenItems = currentFavoriteAspenItems?.length > 0 ? [...currentFavoriteAspenItems] : [];
	let favorites = favoriteAspenItems;

	// check if the aspen item id is already in favorites list
	const favoriteAspenItemIndex = favorites.findIndex((favoriteAspenItem) => favoriteAspenItem?._id === aspenItem?._id);

	// if index found, we need to delete it. If it was not found, add it
	if (favoriteAspenItemIndex > -1) {
		favorites.splice(favoriteAspenItemIndex, 1);
	} else {
		favorites = [...favorites, aspenItem];
	}

	// call BE to edit favorite aspen item = either add it in the list or remove it
	editFavoriteAspenItem(aspenItem._id);
	dispatch({type: favoriteAspenItemsUpdated.type, payload: favorites});
};

export const processColleagueInList = (colleague) => async (dispatch, getState) => {
	let currentList = [...getState().auth.data.userData.colleagueList];

	const findIndex = currentList.findIndex((item) => item.id === colleague);
	if (findIndex > -1) {
		currentList.splice(findIndex, 1);
	} else {
		currentList.push(colleague);
	}

	dispatch({type: colleagueListUpdated.type, payload: currentList});
};

export const changeFilterForOffice = (officeId) => async (dispatch, getState) => {
	if (getState().auth.data.userData.biro.filterFavoritesByOffice === officeId) return;

	dispatch({type: newOfficeForFilterReceived.type, payload: officeId});
};

export const changeDisabledDashboardState = (state) => async (dispatch, getState) => {
	if (getState().auth.ui.disableDashboard === state) return;

	dispatch({type: updateDisabledDashboardState.type, payload: state});
	if (window.location.pathname !== '/') {
		window.location.assign(ROUTES.DEFAULT);
	}
};

export const processFavoriteOffice = (officeId) => async (dispatch, getState) => {
	let currentList = [...(getState().auth.data.userData.biro.favoriteOffices ?? [])];

	const findIndex = currentList.findIndex((item) => item === officeId);
	if (findIndex > -1) {
		currentList.splice(findIndex, 1);
	} else {
		currentList.push(officeId);
	}

	editFavoriteOffice(officeId);
	dispatch({type: favoriteOfficesUpdated.type, payload: currentList});
};

export const changeUserLanguage = (language) => async (dispatch, getState) => {
	i18n.changeLanguage(language);
	const userData = getState().auth.data.userData;
	if (userData) {
		updateMemberLanguage(userData._id, language).then((response) => {
			dispatch(reloadUserData());
			localStorage.setItem('lastVersionUpdate', DateTime.now().toUTC().toLocaleString(DateTime.DATETIME_FULL));
		});
	}
};

//selectors

export const getOfficeBasedOnId = (officeId) => {
	return createSelector(
		(state) => state.auth.data,
		(authData) => {
			let offices = authData.offices;
			const officeBasedOnId = offices.find((office) => office._id === officeId);
			return officeBasedOnId;
		},
	);
};

export const getTeakTypeForOfficeBasedOnId = (officeId) => {
	return createSelector(
		(state) => state.auth.data,
		(authData) => {
			let teakTypeForOffice = {};
			authData.teakTypeArray.forEach((teakType) => {
				if (teakType.office === officeId) teakTypeForOffice = teakType;
			});

			return teakTypeForOffice;
		},
	);
};

export const getTeakTypeObj = (teakTypeId) => {
	return createSelector(
		(state) => state.auth.data,
		(authData) => {
			const teakTypeArray = authData.teakTypeArray;
			let foundTeakType;
			if (!teakTypeArray || teakTypeArray?.length === 0) return foundTeakType;
			teakTypeArray.forEach((teakTypeObj) => {
				teakTypeObj.teakTypes.forEach((teakType) => {
					if (teakType._id === teakTypeId) {
						foundTeakType = teakType;
					}
				});
			});

			return foundTeakType;
		},
	);
};
export const getAspenTypesForOffice = createSelector(
	(state) => state.auth.data,
	(authData) => {
		return authData.aspenTypes?.find((aspenType) => aspenType.officeId === authData.selectedOffice._id);
	},
);
export const getTeakTypeForOffice = createSelector(
	(state) => state.auth.data,
	(authData) => {
		let teakTypeForOffice = {};
		authData.teakTypeArray.forEach((teakType) => {
			if (teakType.office === authData.selectedOffice._id) teakTypeForOffice = teakType;
		});

		return teakTypeForOffice;
	},
);

export const getOfficesForLocationSwitcher = createSelector(
	(state) => state.auth.data.offices,
	(offices) => {
		let officeArray = [];
		offices.forEach((item) => {
			officeArray.push({
				value: item._id,
				label: item.name,
				image: item.image,
				timezone: item.timezone,
			});
		});

		return officeArray;
	},
);

export const getAreasForSelectedOffice = createSelector(
	(state) => state.auth.data,
	(data) => {
		const selectedOfficeId = data.selectedOffice._id;

		const areasForSelectedOffice = data.areas.filter((area) => area.office === selectedOfficeId);

		return areasForSelectedOffice;
	},
);
export const getAreaNameBasedOnId = (areaId) => {
	return createSelector(
		(state) => state.auth.data,
		(authData) => {
			if (areaId === null || areaId === undefined) return;
			const areas = authData.areas;
			const areaNameBasedOnId = areas.find((area) => area._id === areaId);
			return areaNameBasedOnId?.name;
		},
	);
};

export const getAreaTreeForSelectedOffice = createSelector(
	(state) => state.auth.data,
	(data) => {
		const selectedOfficeId = data.selectedOffice._id;

		const areaTreeForSelectedOffice = data.areasTreeList.find((areaTree) => areaTree?.officeId === selectedOfficeId);

		return areaTreeForSelectedOffice;
	},
);

export const setMemberPhoto = (memberId) => async (dispatch, getState) => {
	try {
		const memberPhotos = getState().auth.data.memberPhotos;
		const photoFromStore = memberPhotos.find((item) => item.id === memberId);
		if (photoFromStore) {
			return photoFromStore?.photo;
		}
		const memberPhotoResponse = await getMemberPhoto(memberId, true);
		if (memberPhotoResponse.status === 204) throw 'no member photo';
		dispatch(addMemberPhoto(memberId, window.URL.createObjectURL(new Blob([memberPhotoResponse.data]))));
		return window.URL.createObjectURL(new Blob([memberPhotoResponse.data]));
	} catch (error) {
		console.log(error);
		dispatch(addMemberPhoto(memberId, colleaguePlaceholderPhoto));
		return colleaguePlaceholderPhoto;
	}
};