/**
 * This file contains api calls related to WengeEvents.
 * WengeEvent = Event that can be linked to an office
 */

import axios from 'axios';

const source = axios.CancelToken.source();
let config = { cancelToken: source.token };

const pathUrls = {
	wenge: '/maples/wenge',
	event: '/event',
	list: '/list',
	listOwn: '/listOwn',
	session: '/session',
	attend: '/attend',
	askAQuestion: '/askAQuestion',
	checkIn: '/checkIn',
	attendee: '/attendee',
	eventInvitations: '/eventInvitations',
	eventInvitation: '/eventInvitation',
	self: '/self',
	bookAttendance: '/bookAttendance',
	booth: '/booth',
	outlines: '/outlines',
	banner: '/banner',
	wengeBoothScan: '/wengeBoothScan',
	eventSplashScreenSeen: '/eventSplashScreenSeen',
	form: '/form',
	submit: '/submit',
	submissions: '/submissions',
	edit: '/edit',
	getRegistrationForm: '/getRegistrationForm',
	getDeclineForm:'/getDeclineForm'
};

/**
 *
 * @returns promise with response = list of events in the office
 */
export async function getEvents() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event + pathUrls.list}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {id} wengeEvent = id of event to get sessions for
 * @returns promise with response = list of sessions inside an event
 */
export async function getSessionsInEvent(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.session}${pathUrls.list}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {id} wengeEvent = id of event to get sessions for
 * @returns promise with response = list of sessions inside an event where the user is an attendee
 */
export async function getSessionsInEventWhereUserIsAttendee(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.session}${pathUrls.listOwn}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {id} wengeEvent = id of event to get sessions for
 * @param {id} wengeSession = id of session to check into
 * @returns promise with response = check into a session
 */
export async function checkinIntoSession(wengeEvent, wengeSession) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.session}/${wengeSession}${pathUrls.attend}`;
	return axios(
		{
			method: 'post',
			url: requestUrl,
		},
		config,
	);
}
/**
 *
 * @param {id} wengeEvent = id of event of session
 * @param {id} wengeSession = id of session where to add question
 * @param {string} question = question string to be added
 * @returns promise with response = post question for session
 */
export async function sendQuestionForSession(wengeEvent, wengeSession, question, anonymous) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.session}/${wengeSession}${pathUrls.askAQuestion}`;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				question,
				anonymous
			},
		},
		config,
	);
}
/**
 *
 * @param {id} wengeEvent = id of event to get sessions for
 * @returns promise with response = check the member into the event
 */
export async function checkinIntoEvent(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent + pathUrls.checkIn}`;
	return axios(
		{
			method: 'post',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {id} wengeEvent = id of event to get attendee data
 * @returns promise with response = get attendee data for this event
 */
export async function getSelf(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent + pathUrls.attendee + pathUrls.self}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 * @param {string} email = email of attendee
 * @returns promise with response = get event invitations to display them in app
 */
export async function getEventInvitations(email) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${pathUrls.attendee + pathUrls.eventInvitations}`;
	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				email,
			},
		},
		config,
	);
}

/**
 *
 * @param {id} wengeEvent = id of event where session exists
 * @param {id} wengeSession = id of session to book
 * @returns promise with response = book a slot for user in this session
 */
export async function bookSession(wengeEvent, wengeSession) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent + pathUrls.session}/${wengeSession + pathUrls.bookAttendance}`;
	return axios(
		{
			method: 'post',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {id} wengeEvent = id of event to get booths
 * @returns promise with response = get booths for this event to display on map view
 */
export async function fetchBoothsForEvent(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.booth}${pathUrls.list}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getEventMapOutline(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.outlines}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
			responseType: 'arraybuffer',
		},
		config,
	);
}

/**
 * User saw the splash screen
 * @param {Id} attendeeId = id of attendee
 */
export function updateAttendeeSplashScreenSeen(wengeEvent, attendeeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent + pathUrls.attendee}/${attendeeId + pathUrls.eventSplashScreenSeen
		}`;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				eventSplashScreenSeen: true,
			},
		},
		config,
	);
}

export function updateAttendee(wengeEvent, attendee) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent + pathUrls.attendee}/${attendee._id}`;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				attendee
			},
		},
		config,
	);
}

export async function getEventBanner(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.banner}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
			responseType: 'arraybuffer',
		},
		config,
	);
}

export async function wengeBoothScan(identifier) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event + pathUrls.wengeBoothScan}/${identifier}`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getAttendee(wengeEvent, attendeeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent + pathUrls.attendee}/${attendeeId}`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getEventForms(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.form}${pathUrls.list}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getRegistrationForm(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.form}${pathUrls.getRegistrationForm}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getDeclineForm(wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.form}${pathUrls.getDeclineForm}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function submitEventForm(wengeEvent, wengeForm, attendee, values) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.form}/${wengeForm}${pathUrls.submit}`;
	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				attendee,
				values
			},
		},
		config,
	);
}

export async function editEventForm(wengeEvent, submission, values) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.form}/${submission}${pathUrls.edit}`;
	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				values
			},
		},
		config,
	);
}

export async function getEventFormSubmissions(wengeEvent, wengeForm) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.wenge + pathUrls.event}/${wengeEvent}${pathUrls.form}/${wengeForm}${pathUrls.submissions}`;
	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}